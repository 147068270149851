import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePostSlug } from "../post-slug";
import cn from "classnames";
import { useQuery } from "@tanstack/react-query";

import { Gallery } from "./gallery";
import { MobileGallery } from "./mobile-gallery";
import { sitePath } from "..";
import { ScrollableColumnLayout } from "./scrollable-column-layout";
import { useMarkerById } from "../markers";
import { EndBlock } from "./end-block";

function Backdrop({ active }) {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "fixed inset-0 bg-black transition duration-500 cursor-pointer",
        active ? "opacity-50" : "opacity-0"
      )}
      onClick={() => {
        navigate(sitePath);
      }}
    />
  );
}

function Content({ post }) {
  const category = useMarkerById(post.id)?.category;

  return (
    <div className="px-6 md:p-10 md:pr-24">
      {category && (
        <a
          href={category.link}
          className="font-semibold text-em1 uppercase text-sm mb-1.5 mt- inline-block hover:underline"
        >
          {category.name}
        </a>
      )}
      <h2 className="text-3xl font-semibold uppercase mb-8 max-w-2xl box-content">
        {post.title.rendered}
      </h2>
      <div
        className="prose"
        dangerouslySetInnerHTML={{ __html: post.content.rendered }}
      />
      <EndBlock post={post} />
    </div>
  );
}

const splitIsActive = () =>
  typeof window != "undefined" && window.innerWidth >= 1024;

function SplitLayout({ post }) {
  React.useEffect(() => {
    scrollContainerRef.current.scrollTop = 0;
  }, [post]);

  const galleryImages = post?.acf["map-gallery"];

  const [splitActive, setSplitActive] = React.useState(splitIsActive);
  React.useEffect(() => {
    const handleResize = () => setSplitActive(splitIsActive());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const scrollContainerRef = React.useRef(null);

  return (
    <div
      ref={scrollContainerRef}
      className="overflow-auto h-full"
      style={{ overflowBehaviour: "contain" }}
    >
      {splitActive ? (
        galleryImages ? (
          <ScrollableColumnLayout
            scrollContainerRef={scrollContainerRef}
            gridTemplateColumns="2fr 3fr"
          >
            <Gallery images={galleryImages} />
            <div className="pb-8">{post && <Content post={post} />}</div>
          </ScrollableColumnLayout>
        ) : (
          <div className="pb-8">{post && <Content post={post} />}</div>
        )
      ) : (
        <div>
          <div className="pt-28 md:pt-0 pb-16">
            {post && <Content post={post} />}
            {galleryImages && <MobileGallery images={galleryImages} />}
          </div>
        </div>
      )}
    </div>
  );
}

function useHomeLinkClickjacked(active) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!active) return;

    const homeLink = document.getElementsByClassName("home-link")[0];
    if (!homeLink) return;

    const handleClick = (evt) => {
      evt.preventDefault();
      navigate(sitePath);
    };

    homeLink.addEventListener("click", handleClick);
    return () => homeLink.removeEventListener("click", handleClick);
  }, [active, navigate]);
}

function CloseLink() {
  return (
    <div className="absolute top-0 right-4 hidden z-10 md:block">
      <Link
        to={sitePath}
        className="w-20 h-20 flex justify-center items-center text-em1 hover:text-em1-offset"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 2L23 23" />
          <path d="M23 2L2 23" />
        </svg>
      </Link>
    </div>
  );
}

function useEditLinkClickjacked(post, active) {
  React.useEffect(() => {
    if (!post || !active) return;

    const editLink = document.querySelector("#wp-admin-bar-edit a");
    if (!editLink) return;

    const prevHref = editLink.getAttribute("href");
    editLink.setAttribute(
      "href",
      `${prevHref.replace(/post=\d+/, `post=${post.id}`)}`
    );

    return () => editLink.setAttribute("href", prevHref);
  }, [post, active]);
}

function UI({ active, slug }) {
  const { data } = useQuery(["getPost", slug], () =>
    slug
      ? fetch(`/wp-json/wp/v2/posts/?slug=${slug}&acf_format=standard`)
          .then((resp) => resp.json())
          .then((posts) => (posts.length ? posts[0] : null))
      : null
  );

  const postRef = React.useRef(data);
  if (slug) postRef.current = data;

  active &= !!postRef.current;

  useEditLinkClickjacked(data, active);

  return (
    <>
      <Backdrop active={active} />
      <div className="fixed inset-0 pointer-events-none">
        <div
          className={cn(
            "md:px-24 md:py-16 mx-auto flex flex-col justify-center h-full",
            postRef.current?.acf["map-gallery"] ? "max-w-8xl" : "max-w-5xl"
          )}
        >
          <div
            className={cn(
              "relative bg-bg h-full transform transition duration-500 shadow-xl",
              active ? "pointer-events-auto" : "translate-y-96 opacity-0"
            )}
            style={{ maxHeight: "64rem" }}
          >
            <CloseLink />
            <SplitLayout post={postRef.current} />
          </div>
        </div>
      </div>
    </>
  );
}

function BackButton({ visible }) {
  return (
    <Link
      to={sitePath}
      className={cn(
        "w-10 h-24 fixed top-0 left-20 flex justify-center items-center text-em1 z-10 transition sm:hidden",
        !visible && "opacity-0 pointer-events-none"
      )}
    >
      <svg
        width="13"
        height="21"
        viewBox="0 0 13 21"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        className="pointer-events-none"
      >
        <path d="M11 2.8713L3 10.8713L11 18.8713" />
      </svg>
    </Link>
  );
}

export function Popup() {
  const slug = usePostSlug();

  const visible = !!slug;

  useHomeLinkClickjacked(visible);

  return (
    <div
      className={cn("relative", !slug && "pointer-events-none")}
      style={{ zIndex: 401 }}
    >
      <BackButton visible={visible} />
      <UI active={visible} slug={slug} />
    </div>
  );
}
