import React from "react";
import "photoswipe/dist/photoswipe.css";
import Lightbox from "photoswipe/dist/photoswipe-lightbox.esm";
import Photoswipe from "photoswipe/dist/photoswipe.esm";

import styles from "./popup.module.css";

export function Gallery({ images }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const lightbox = new Lightbox({
      gallery: ref.current,
      children: "a",
      bgOpacity: 1,
      pswpModule: Photoswipe,
    });

    lightbox.on("uiRegister", () => {
      lightbox.pswp.ui.registerElement({
        html: "",
        appendTo: "root",
        order: 9,
        className: styles.galleryCaption,
        onInit(elem) {
          lightbox.pswp.on("change", () => {
            const img =
              lightbox.pswp.currSlide.data.element.querySelector("img");
            elem.innerText = img?.getAttribute("alt") || "";
          });
        },
      });
    });

    lightbox.init();
    return () => {
      lightbox.destroy();
    };
  }, []);

  return (
    <div ref={ref} className="flex flex-col gap-4">
      {images.map((img, i) => (
        <a
          key={i}
          href={img.url}
          className="block relative group"
          data-pswp-src={img.url}
          data-pswp-width={img.width}
          data-pswp-height={img.height}
        >
          <img
            src={img.sizes.large}
            className="select-none w-full"
            alt={img.caption}
            draggable={false}
          />
          {img.caption && (
            <span
              className="block absolute inset-x-0 bottom-0 pb-4 text-center pointer-events-none text-bg opacity-0 transition group-hover:opacity-100"
              style={{ textShadow: "0 2px rgba(0, 0, 0, 0.2)" }}
            >
              {img.caption}
            </span>
          )}
        </a>
      ))}
    </div>
  );
}
