import React from "react";

const Context = React.createContext(null);

export function useMarkerById(id) {
  return React.useContext(Context)?.markersById[id];
}

export function useMarkerBySlug(slug) {
  return React.useContext(Context)?.markersBySlug[slug];
}

export function useMarkers() {
  return React.useContext(Context)?.markers;
}

export function useVisibleMarkers() {
  return React.useContext(Context)?.visibleMarkers;
}

export function MarkersProvider({
  children,
  markers: markersRawData,
  categories,
}) {
  const markers = React.useMemo(
    () =>
      markersRawData.map((marker) => ({
        ...marker,
        category: categories.find((cat) => cat.slug == marker.category),
      })),
    [markersRawData, categories]
  );

  const markersBySlug = React.useMemo(() => {
    const map = {};
    markers.forEach((marker) => {
      map[marker.slug] = marker;
    });
    return map;
  }, [markers]);

  const markersById = React.useMemo(() => {
    const map = {};
    markers.forEach((marker) => {
      map[marker.id] = marker;
    });
    return map;
  }, [markers]);

  const visibleMarkers = React.useMemo(
    () => markers.filter((marker) => marker.type != "hidden"),
    [markers]
  );

  const ctx = React.useMemo(
    () => ({
      markers,
      markersBySlug,
      markersById,
      visibleMarkers,
    }),
    [markers, markersBySlug, markersById, visibleMarkers]
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
