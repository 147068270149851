import React from "react";
import { Link } from "react-router-dom";
import { sitePath } from "../..";
import { useMarkerById } from "../../markers";

function MapLink({ label, post }) {
  const marker = useMarkerById(post);

  if (!marker) return null;

  return (
    <div className="relative pl-8">
      <div className="absolute top-0.5 left-0 pointer-events-none">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </div>
      <Link
        to={`${sitePath}${marker.name}/`}
        className="relative font-semibold text-xl hover:underline"
      >
        {label || marker.name}
      </Link>
    </div>
  );
}

function Links({ post }) {
  const mapLinks = post.acf["map-links"];

  if (!mapLinks || !mapLinks.length) return null;

  return (
    <div className="flex flex-col gap-3">
      {mapLinks.map((link, i) => (
        <MapLink {...link} key={i} />
      ))}
    </div>
  );
}

const buttonClassNameBase =
  "inline-block relative text-xl rounded-full transition font-semibold text-center py-3 px-7";

export function EndBlock({ post }) {
  const category = useMarkerById(post.id)?.category;
  const categoryLinkCaption = post.acf["map-category-link-caption"];

  return (
    <div className="mt-12 flex flex-col gap-8 mb-8 xl:justify-between xl:flex-row">
      <div>
        <Links post={post} />
      </div>
      <div className="flex flex-col gap-4 flex-shrink-0 max-w-xs">
        {category && categoryLinkCaption && (
          <div>
            <a
              href={category.link}
              className={`${buttonClassNameBase} text-bg bg-em1 hover:bg-em1-offset`}
            >
              {categoryLinkCaption}
            </a>
          </div>
        )}
        <div>
          <Link
            to={sitePath}
            className={`${buttonClassNameBase} text-bg bg-gray-300 hover:bg-gray-400`}
          >
            Zurück zur Karte
          </Link>
        </div>
      </div>
    </div>
  );
}
