import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { PostSlugProvider } from "./post-slug";
import { LeafletMap } from "./leaflet";
import { Popup } from "./popup";
import { MarkersProvider } from "./markers";

const rootElem = document.getElementById("map-root");

export const sitePath = rootElem?.getAttribute("data-site-path") || "/";
export const mapTilesUrl = rootElem?.getAttribute("data-map-tiles-url") || "";

if (rootElem) {
  const root = createRoot(rootElem);
  const queryClient = new QueryClient();
  const categories = window.categoriesData || [];
  const markers = window.markersData || [];

  root.render(
    <BrowserRouter>
      <PostSlugProvider>
        <QueryClientProvider client={queryClient}>
          <MarkersProvider markers={markers} categories={categories}>
            <LeafletMap />
            <Popup />
          </MarkersProvider>
        </QueryClientProvider>
      </PostSlugProvider>
    </BrowserRouter>
  );
}
