import React from "react";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import cn from "classnames";

import { mapTilesUrl } from "..";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { usePostSlug } from "../post-slug";
import { Marker } from "./marker";
import { project } from "./projection";
import { useVisibleMarkers } from "../markers";
import { fixVisibleTileBorders } from "./fix-visible-tile-borders";

fixVisibleTileBorders();

const bounds = [project([-1, -1]), project([1, 1])];

function PanControl() {
  const markers = useVisibleMarkers();

  const markerPositionMap = React.useMemo(() => {
    const map = {};
    markers.forEach(({ name, position }) => {
      map[name] = position;
    });
    return map;
  }, [markers]);

  const slug = usePostSlug();
  const map = useMap();

  React.useEffect(() => {
    if (slug) {
      const position = markerPositionMap[slug];
      if (position) map.panTo(project(position));
    }
  }, [slug, map, markerPositionMap]);

  return null;
}

function ZoomControllBtn({ children, className, onClick, disabled }) {
  return (
    <button
      className={cn(
        className,
        disabled && "text-opacity-50",
        "bg-bg text-em1 appearance-none w-10 h-10 transition flex justify-center items-center"
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

function ZoomControl() {
  const map = useMap();
  const [zoom, setZoom] = React.useState(map.getZoom());

  React.useEffect(() => {
    const handleZoom = () => {
      setZoom(map.getZoom());
    };

    map.on("zoom", handleZoom);
    return () => map.off("zoom", handleZoom);
  }, [map]);

  return (
    <div
      className="fixed bottom-14 right-4"
      style={{ zIndex: 401 }}
      onDoubleClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
      onDrag={(evt) => {
        evt.stopPropagation();
      }}
    >
      <ZoomControllBtn
        className="rounded-t-full border-b-2 border-bg-offset"
        onClick={() => {
          map.zoomIn();
        }}
        disabled={zoom >= map.getMaxZoom()}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 10H18M10 2V18" />
        </svg>
      </ZoomControllBtn>
      <ZoomControllBtn
        className="rounded-b-full"
        onClick={() => {
          map.zoomOut();
        }}
        disabled={zoom <= map.getMinZoom()}
      >
        <svg
          width="20"
          height="4"
          viewBox="0 0 20 4"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 2H18" />
        </svg>
      </ZoomControllBtn>
    </div>
  );
}

export function LeafletMap() {
  const markers = useVisibleMarkers();
  const zoom = React.useState(() => (window.innerWidth < 768 ? 4 : 5))[0];

  return (
    <MapContainer
      className="bg-map-bg h-screen"
      attributionControl={false}
      crs={Leaflet.CRS.Simple}
      maxBounds={bounds}
      maxBoundsViscosity={1}
      center={project([0, 0])}
      zoom={zoom}
      zoomControl={false}
    >
      <PanControl />
      <ZoomControl />
      <TileLayer
        url={mapTilesUrl}
        minZoom={3}
        maxZoom={7}
        noWrap={true}
        bounds={bounds}
        detectRetina={window.devicePixelRatio >= 2}
        keepBuffer={64}
      />
      {markers.map((props) => (
        <Marker {...props} key={props.name} />
      ))}
    </MapContainer>
  );
}
