import React from "react";

function Column({ registerColumn, children }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const ctrl = registerColumn(ref);

    const observer = new ResizeObserver(([{ contentRect }]) => {
      ctrl.updateHeight(contentRect.height);
    });
    observer.observe(ref.current);

    ctrl.updateHeight(ref.current.getBoundingClientRect().height);

    return () => {
      observer.disconnect();
      ctrl.dispose();
    };
  }, [registerColumn]);

  return (
    <div>
      <div ref={ref}>{children}</div>
    </div>
  );
}

export function ScrollableColumnLayout({
  children,
  scrollContainerRef,
  gridTemplateColumns,
}) {
  const columnsRef = React.useRef([]);

  const [columnData, setColumnData] = React.useState(null);

  const updateColumnData = React.useCallback(() => {
    setColumnData(
      columnsRef.current.length < 2
        ? null
        : {
            orderedByHeight: columnsRef.current.sort(
              (a, b) => b.height - a.height
            ),
          }
    );
  }, []);

  const registerColumn = React.useCallback(
    (columnRef) => {
      const column = {
        elemRef: columnRef,
        height: 0,
      };

      columnsRef.current = [...columnsRef.current, column];
      updateColumnData();

      return {
        updateHeight(height) {
          if (height == column.height) return;
          column.height = height;
          updateColumnData();
        },
        dispose() {
          columnsRef.current = columnsRef.current.filter(
            (other) => other == column
          );
          updateColumnData();
        },
      };
    },
    [updateColumnData]
  );

  const [scrollContainerHeight, setScrollContainerHeight] = React.useState(0);
  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const observer = new ResizeObserver(([{ contentRect }]) => {
      setScrollContainerHeight(contentRect.height);
    });
    observer.observe(scrollContainer);

    return () => observer.disconnect();
  }, [scrollContainerRef]);

  React.useEffect(() => {
    if (!columnData) return;

    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const [heighestColumn, ...dynamicColumns] = columnData.orderedByHeight;

    const handleScroll = () => {
      heighestColumn.elemRef.current.style.transform = undefined;
      const scrollEvo =
        scrollContainer.scrollTop /
        Math.max(1, heighestColumn.height - scrollContainerHeight);

      dynamicColumns.forEach((column) => {
        column.elemRef.current.style.transform = `translateY(${
          column.height > scrollContainerHeight
            ? scrollEvo * (heighestColumn.height - column.height)
            : scrollContainer.scrollTop
        }px)`;
      });
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [columnData, scrollContainerHeight, scrollContainerRef]);

  return (
    <div style={{ display: "grid", gridTemplateColumns }}>
      {React.Children.map(children, (child, i) => (
        <Column key={i} registerColumn={registerColumn}>
          {child}
        </Column>
      ))}
    </div>
  );
}
