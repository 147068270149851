import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

export function MobileGallery({ images }) {
  return (
    <div className="overflow-hidden">
      <div className="px-6">
        <Swiper
          spaceBetween={16}
          style={{ overflow: "visible" }}
          loop
          grabCursor
          touchEventsTarget="container"
        >
          {images.map((img, i) => (
            <SwiperSlide
              key={i}
              className="flex flex-col justify-center h-full"
            >
              <div className="relative pt-2/3">
                <img
                  src={img.sizes.large}
                  className="absolute inset-0 w-full h-full select-none object-cover"
                  alt={img.caption}
                  draggable={false}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
