import React from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { Marker as LeafletMarker, useMap } from "react-leaflet";
import Leaflet from "leaflet";
import cn from "classnames";

import { project } from "../projection";

const iconSmallSize = 24;
const defaultIconSize = 288;

function IconImage({ src, setImgSize, scaleFactor }) {
  const ref = React.useRef(null);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    const img = ref.current;
    if (!img) return;

    const iconSize = defaultIconSize * scaleFactor;
    const observer = new ResizeObserver(([{ contentRect }]) => {
      const { width, height } = contentRect;
      const extent = width > height ? width : height;
      if (extent <= iconSize) return;

      const factor = iconSize / extent;
      const imgSize = [Math.round(factor * width), Math.round(factor * height)];

      img.style.width = `${imgSize[0]}px`;
      img.style.height = `${imgSize[1]}px`;

      setImgSize(imgSize);
      setActive(true);
    });

    observer.observe(img);
    return () => observer.disconnect();
  }, [setImgSize, scaleFactor]);

  return (
    <img
      src={src}
      className={cn(
        !active && "opacity-0",
        scaleFactor < 2 ? "group-hover:scale-110" : "group-hover:scale-105",
        "transform transition-transform duration-500"
      )}
      ref={ref}
    />
  );
}

function MarkerIcon({ iconUrl, iconScaleFactor, color, onClick, map }) {
  const imgRef = React.useRef(null);
  const btnRef = React.useRef(null);
  const offsetRef = React.useRef(null);
  const [active, setActive] = React.useState(false);
  const [zoom, setZoom] = React.useState(map.getZoom());
  const [imgSize, setImgSize] = React.useState(null);
  const [btnSize, setBtnSize] = React.useState(null);

  const isBig = zoom >= 4;

  React.useEffect(() => {
    const handleZoom = () => {
      setZoom(map.getZoom());
    };

    map.on("zoom", handleZoom);
    return () => map.off("zoom", handleZoom);
  }, [map]);

  React.useEffect(() => {
    const img = imgRef.current;

    const [w, h] = isBig && imgSize ? imgSize : [iconSmallSize, iconSmallSize];

    setBtnSize([w, h]);

    if (!isBig && imgSize) {
      img.style.left = `${Math.round((w - imgSize[0]) / 2)}px`;
      img.style.top = `${Math.round((h - imgSize[1]) / 2)}px`;
    } else {
      img.style.left = img.style.top = "";
    }

    setActive(true);
  }, [isBig, imgSize]);

  React.useEffect(() => {
    if (!btnSize) return;

    const btn = btnRef.current;
    const offset = offsetRef.current;

    const [w, h] = btnSize;
    btn.style.width = `${w}px`;
    btn.style.height = `${h}px`;
    btn.style.transform = `scale(${zoom < 5 ? 0.5 : zoom == 6 ? 1.5 : 1})`;
    offset.style.transform = `translate(${-Math.round(w / 2)}px, ${-Math.round(
      h / 2
    )}px)`;
  }, [btnSize, zoom]);

  React.useEffect(() => {
    const btn = btnRef.current;

    let downPos = null;

    const handleMouseDown = ({ clientX, clientY }) => {
      downPos = [clientX, clientY];
    };

    const handleClick = ({ clientX, clientY }) => {
      if (downPos) {
        const x = clientX - downPos[0];
        const y = clientY - downPos[1];
        downPos = null;
        if (Math.sqrt(x * x + y * y) > 16) return;
      }

      onClick();
    };

    btn.addEventListener("mousedown", handleMouseDown);
    btn.addEventListener("click", handleClick);

    return () => {
      btn.removeEventListener("mousedown", handleMouseDown);
      btn.removeEventListener("click", handleClick);
    };
  }, [onClick]);

  return (
    <div ref={offsetRef}>
      <button
        className={cn(
          !active && "opacity-0",
          "relative group block appearance-none transition-transform duration-500"
        )}
        ref={btnRef}
      >
        <div
          className={cn(
            isBig && "hidden",
            "relative w-full h-full shadow-lg rounded-full"
          )}
          style={{
            background: color,
          }}
        />
        <div
          className={cn(
            !isBig &&
              "scale-0 opacity-25 group-hover:scale-75 group-hover:opacity-100",
            "absolute top-0 left-0 pointer-events-none transform transition duration-300"
          )}
          ref={imgRef}
        >
          <IconImage
            src={iconUrl}
            scaleFactor={iconScaleFactor}
            setImgSize={setImgSize}
          />
        </div>
      </button>
    </div>
  );
}

export function Marker({
  name,
  position,
  iconUrl,
  iconScaleFactor,
  category,
  type,
  link,
}) {
  const navigate = useNavigate();
  const iconElem = React.useMemo(() => document.createElement("div"), []);
  const map = useMap();
  const color = category.color;

  const iconRoot = React.useMemo(() => createRoot(iconElem), [iconElem]);
  React.useEffect(() => () => iconRoot.unmount(), [iconRoot]);

  const handleClick = React.useCallback(() => {
    if (type == "post") navigate(`/${name}/`);
    else document.location.href = link;
  }, [name, navigate, type, link]);

  React.useEffect(() => {
    iconRoot.render(
      <MarkerIcon
        iconUrl={iconUrl}
        iconScaleFactor={iconScaleFactor}
        color={color}
        onClick={handleClick}
        map={map}
      />
    );
  }, [iconUrl, color, iconRoot, handleClick, map]);

  const icon = React.useMemo(() => {
    return Leaflet.divIcon({
      iconSize: [0, 0],
      iconAnchor: [0, 0],
      html: iconElem,
      className: "cursor-auto",
    });
  }, [iconElem]);

  const projectedPosition = React.useMemo(() => project(position), [position]);

  return (
    <LeafletMarker
      position={projectedPosition}
      icon={icon}
      autoPanOnFocus={false}
    />
  );
}
