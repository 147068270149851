import React from "react";
import { useLocation } from "react-router-dom";
import { sitePath } from ".";

const Context = React.createContext(null);

export function usePostSlug() {
  return React.useContext(Context);
}

export function PostSlugProvider({ children }) {
  const { pathname } = useLocation();

  const slug = React.useMemo(
    () =>
      (sitePath ? pathname.substring(sitePath.length) : pathname).match(
        /^([^/]+)/
      )?.[1],
    [pathname]
  );

  return <Context.Provider value={slug}>{children}</Context.Provider>;
}
