import Leaflet from "leaflet";

let fixed = false;

export function fixVisibleTileBorders() {
  if (fixed) return;
  fixed = true;
  const originalInitTile = Leaflet.GridLayer.prototype._initTile;
  Leaflet.GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile);
      const { x, y } = this.getTileSize();
      tile.style.width = `${x + 0.5}px`;
      tile.style.height = `${y + 0.5}px`;
    },
  });
}
